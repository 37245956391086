
.pagination {
    display: flex;
    align-items: center;
    margin-top: 30px;
}
@media (max-width: 480px) {
  .pagination {
    margin-top: 50px;
  }
}
.pagination-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pagination-btns {
  border: 1px solid #ddd;
  padding: 0.5em 0.75em;
  font-weight: 500;
  color: #2b2e34;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
@media (max-width: 480px) {
  .pagination-btns {
    font-size: 11px;
  }
}
.pagination-btns:hover {
  color: #ffffff;
  background-color: #37405f;
  border-color: #37405f;
  text-decoration: none;
}
.pagination-active {
  color: #ffffff;
  background-color: #37405f;
}
.pagination-next {
  cursor: pointer;
  transition: 0.3s;
  padding: 0.5em 0.75em;
  border: 1px solid #ddd;
  text-decoration: none;
  color: #000;
  text-decoration: none !important;
}
.pagination-next:hover {
  color: #ffffff;
  background-color: #37405f;
  border-color: #37405f;
  
  text-decoration: none !important;
}
@media (max-width: 480px) {
  .pagination-next {
    padding: 10px;
    text-decoration: none !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cl-red {
  color: red;
}
